.indicators {
    text-align: center;
    margin-top: 24px;
}

.left-indicator {
    border: none;
    background-color: #EFEDEA;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 12px 16px;
}

.right-indicator {
    border: none;
    background-color: #CE9931;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: white;
    padding: 12px 16px;
}

.quote-title {
    line-height: 1.1;
    font-family: Playfair !important;
    color: #313131;
}

.quote-subtitle {
    font-size: 1.3rem;
    line-height: 1.5;
    font-family: Mulish !important;
}

.quote-bg {
    background: #FFFFFFB2;
    border-radius: 14px;
    backdrop-filter: blur(5px);
    padding: 24px 4px;
}

.des {
    height: 5px;
    width: 36px;
    margin-left: 12px;
}

.h-200 {
    height: 140px;
}

.maxh-500 {
    height: 52vw;
}

@media (min-width: 450px) {
    .h-200 {
        height: 200px;
    }
}

@media (max-width: 992px) {

    .quote-title {
        font-size: 2.1rem !important;
    }

    .quote-subtitle {
        font-size: 1rem !important;
    }
}

@media (min-width: 768px) {

    .maxh-500 {
        height: 35vw;
    }

    .indicators {
        position: absolute;
        bottom: -22px;
        left: 32px;
    }

    .des {
        width: 52px !important;
        margin-left: 24px !important;
    }
}

@media (min-width: 992px) {
    .maxh-500 {
        height: 395px;
    }

    .quote-title {
        font-size: 3rem;
    }
}

@media (min-width: 1200px) {
    .maxh-500 {
        height: 415px;
    }

    .quote-title {
        font-size: 3.5rem;
    }
}

@media (min-width: 1400px) {
    .maxh-500 {
        height: 485px;
    }
}


.new-property {
    background: #F5EBD6;
    padding: 100px 0;
}

.card-title {
    text-transform: uppercase;
}

.yellow-line {
    background: #CE9931;
    height: 2px;
}

.scroll-smooth {
    scroll-behavior: smooth;
}

.nowrap {
    white-space: nowrap;
}

.card-img-top {
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    height: 400px;
    object-fit: contain;
}

.border-yellow {
    border: 1px solid #CE9931;
}

.new-props-title {
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    padding: 0 16px;
}

.text-overflow-elisps {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 7;
    -moz-line-clamp: 7;
    -ms-line-clamp: 7;
    line-clamp: 7;
}