.sidebar-content {
    background-color: #636f83;
    background-image: linear-gradient(45deg, #3c4b64 0%, #212333 100%);
    width: 100%;
    height: 100%;
    position: relative;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff99;
    white-space: nowrap;
}

.sidebar-item:hover {
    background: #00001517;
    color: white;
}

.sidebar-header {
    border-bottom: 1px solid #ffffff1a;
    height: 68px;
    white-space: nowrap;
}