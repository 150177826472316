.back-icon {
    -webkit-text-stroke: 1px;
}

.more-image-bg {
    background: #00000080;
    height: calc(100% - 8px);
    width: calc(100% - 8px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.more-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.indicator-l {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.indicator-r {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

@media (min-width: 576px) {
    .p-sm-32 {
        padding: 32px !important;
    }
}

@media (max-width: 575px) {
    .p-sm-32 {
        padding: 16px !important;
    }
}

.props-image {
    max-height: 140px;
    min-height: 100px;
    object-fit: cover;
    object-position: center;
}

.nearby-props .gm-style .gm-style-iw-tc {
    display: none;
}

.nearby-props .gm-style .gm-style-iw-c {
    cursor: pointer;
    padding: 0;
}

.nearby-props .gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.nearby-props .gm-ui-hover-effect {
    background: rgb(255 255 255 / 80%) !important;
    top: 0 !important;
    right: 0 !important;
}

.nearby-props .gm-ui-hover-effect:focus-visible {
    outline: none !important;
}