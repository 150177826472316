.download-file {
    position: absolute;
    bottom: 12px;
    right: 12px;
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}