.search-property {
    box-shadow: 5.1282057762146px 7.69230842590332px 56.41026306152344px 0px #0000001F;
}

.border-top-gray {
    border-top: 1px solid #D9D9D9;
}

.pagination {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
}

.prop-title {
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.search-box {
    padding-top: 66px;
    position: sticky;
    top: 0px;
    z-index: 10;
}

.image-height{
    height: 300px;
    object-fit: cover;
    object-position: center;
}

@media (min-width: 768px) {
    .image-height{
        height: 200px;
    }
}