@media (max-width: 991px) {
  .mt-66 {
    padding-top: 66px !important;
  }
}

.header {
  position: sticky;
  top: 0px;
  z-index: 100;
}

.map-logo {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: end;
  padding-right: 32px;
  padding-bottom: 32px;
  z-index: 5;
  background: transparent !important;
}

.mapview-popover {
  box-shadow: 0px 0px 36px 0px #00000040;
  background: white;
  border-radius: 12px;
  padding: 6px 6px 16px 16px;
  margin-right: 32px;
  margin-bottom: 8px;
}

.explore-icon {
  font-size: 20px;
  display: inline-block;
  rotate: y 180deg;
  margin-left: 2px;
  line-height: normal;
}