.footer {
    background-color: #1C1C1C;
    color: #ffffff;
}

.treadmark {
    background: #111111;
    padding: 24px 0;
}

.treadmark h6 {
    font-weight: 300;
}

.brand-footer {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 1px;
}

.brand-sm-footer {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 800;
    letter-spacing: 4px;
    text-align: center;
}