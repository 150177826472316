.nav-bar-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: #32325d05 0 2px 5px -1px, #0000000d 0 1px 3px -1px;
    font-size: 16px;
}

.profile-img {
    border: 1px solid darkgrey;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 12px;
}

.logout{
    color: red;
}