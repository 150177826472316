.heading-bg {
    background: url('../../assets/Images/about-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
}

.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.black-des {
    height: 3px;
    background: #000;
    width: 100px;
}

.video-box {
    height: 450px;
    width: 100%;
    background: #CE9931;
    border-radius: 16px;
}

.icon-box {
    box-shadow: 0px 7.822727203369141px 7.822727203369141px 0px #00000014;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1200px) {
    .fs-56 {
        font-size: 56px !important;
    }
}

.fs-30{
    font-size: calc(30px + 1vw);
}