.admin-title {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    background: linear-gradient(45deg, #3c4b64 0%, #212333 100%);
}

.add-admin:hover {
    color: #CE9931;
    transition: 200ms;
}

.clickable-icon {
    cursor: pointer;
    color: #ff0019;
}

.disabled-box {
    cursor: no-drop;
    width: fit-content;
}

.disabled-icon {
    pointer-events: none;
    color: rgba(255, 0, 25, 0.5);
}