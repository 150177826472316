.search-property {
    box-shadow: 5.1282057762146px 7.69230842590332px 56.41026306152344px 0px #0000001F;
    background: white;
}

.category-btn {
    border: none;
    background: #fff;
    padding: 16px;
    color: #000000;
    border-bottom: 3px solid white;
    padding: 1.5rem;
}

.active-category {
    border-bottom: 3px solid #CE9931 !important;
    color: #CE9931 !important;
}

.search-btn {
    padding: 6px 12px !important;
}

.border-left {
    border-left: 1px solid #00000033;
}

.border-right {
    border-right: 1px solid #00000033;
}

.map-search-autocomplete {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 100%);
    width: 100%;
    z-index: 100;
}

.map-search-autocomplete .suggestion-item {
    padding: 8px 16px;
}

.map-search-autocomplete .suggestion-item--active {
    padding: 8px 16px;
}

.property-select:focus-visible {
    outline: none;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    position: absolute;
    transform: translate3d(-50px, 40px, 0px);
    top: 0px;
    left: 0px;
}

.dropdown-item.active,
.dropdown-item:active{
    color: #000000 !important;
    background: #f8f9fa !important;
}