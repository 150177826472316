.navbar{
    background: white;
}

.brand {
    color: #28313A;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    font-family: Mulish !important;
}

.brand-sm {
    color: #CE9931;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 800;
    letter-spacing: 4px;
    text-align: center;
    font-family: Mulish !important;
}

.black-text {
    color: black;
}

.navbar-toggler {
    background: transparent;
    box-shadow: none;
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

@media (max-width: 991px) {
    .navbar-expand-lg {
        position: absolute !important;
        z-index: 5;
        background: #fff;
        width: 100%;
    }

    .nav-collapse {
        flex-basis: 100%;
        flex-grow: 1;
    }
}