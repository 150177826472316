.info-image {
    max-height: 200px;
    min-height: 150px;
    object-fit: cover;
    object-position: center;
}

.map-view .gm-style .gm-style-iw-tc {
    display: none;
}

.map-view .gm-style .gm-style-iw-c {
    cursor: pointer;
    padding: 0;
}

.map-view .gm-style .gm-style-iw-d {
    overflow: hidden !important;
}

.map-view .gm-ui-hover-effect {
    background: rgb(255 255 255 / 80%) !important;
    top: 0 !important;
    right: 0 !important;
}

.map-view .gm-ui-hover-effect:focus-visible {
    outline: none !important;
}