body {
  margin: 0;
  font-family: Mulish !important;
}

.active {
  color: #CE9931 !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #21233373;
}

::-webkit-scrollbar-thumb:hover {
  background: #2123338c;
}

.bg-yellow {
  background: #CE9931;
}

.bg-lightgray {
  background: #989898;
}

.text-gray {
  color: #5C5C5C;
}

.text-darkgray {
  color: #3E3E3E;
}

.text-lightgray {
  color: #919191;
}

.text-yellow {
  color: #CE9931 !important;
}

.btn-yellow {
  background: #CE9931 !important;
  color: #fff !important;
  padding: 10px 48px !important;
  border-radius: 7px !important;
  border: 2px solid #CE9931 !important;
}

.btn-outline-yellow {
  border: 2px solid #CE9931 !important;
  padding: 10px 48px !important;
  border-radius: 7px !important;
}

.btn-outline-gray {
  border: 1px solid #0000004D !important;
  border-radius: 5px !important;
  padding: 6px 28px !important;
  font-size: 18px !important;
}

.search-input {
  border: none;
}

.search-input:focus-visible {
  outline: none;
}

.custom-checkbox {
  appearance: none;
  border: 1px solid #CE9931;
  padding: 7px;
  border-radius: 3px;
  position: relative;
}

.custom-checkbox:checked {
  background-color: #CE9931;
  border: 1px solid #CE9931;
  color: #99a1a7;
}

.custom-checkbox:checked:after {
  font-family: sans-serif;
  content: "✔";
  color: white;
  width: 1em;
  font-size: 14px;
  position: absolute;
  top: -3px;
  left: 2px;
}

.card {
  box-shadow: 10.527701377868652px 13.159626007080078px 26.319252014160156px 0px #98989829;
  border: none !important;
  border-radius: 15px !important;
}

.container-lg {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.input-box {
  position: relative;
}

.label {
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
  font-size: 18px;
}

.input {
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #0000004D;
  border-radius: 5px;
}

.error-input {
  border-color: #f44336 !important;
}

.input:focus-within {
  outline: 1px solid #CE9931;
}

.select-input {
  appearance: none;
}

.mobile-select-menu {
  background: url('/src/assets/Images/down-arrow.png') no-repeat;
  background-position: right 8px center;
  background-size: 12px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 4px 24px 4px 16px !important;
}

.error-font {
  color: #f44336 !important;
  font-size: 13px;
  margin-top: -20px;
  display: block;
}

.show-hide-icon {
  position: absolute;
  top: 54%;
  right: 14px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-14 {
  font-size: 14px !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Inter !important;
  font-weight: 600 !important;
}

.rh5v-Overlay_inner {
  height: 48px !important;
  width: 48px !important;
  border-radius: 50% !important;
  background: #CE9931 !important;
  margin-left: 0 !important;
  transform: translate(-50%, -50%) !important;
}

.rh5v-DefaultPlayer_component {
  background: #fff !important;
}

.ff-inter {
  font-family: Inter !important;
}

.no-border.ag-cell:focus {
  border-color: transparent !important;
  outline: none;
}

.back-icon {
  -webkit-text-stroke: 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.view-images-modal {
  --bs-modal-width: 65% !important;
}

.slider-box div div {
  height: calc(100% - 5px);
}