.title-bg {
    background: url('../../assets/Images/sell-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60vh;
}

.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.black-des {
    height: 3px;
    background: #000;
    width: 100px;
}

.video-box {
    height: 450px;
    width: 100%;
    background: #CE9931;
    border-radius: 16px;
}

@media (min-width: 1200px) {
    .fs-56 {
        font-size: 56px !important;
    }
}

.fs-30 {
    font-size: calc(30px + 1vw);
}

.active-cat {
    background: #F0E0C1 !important;
    border: 1px solid #F0E0C1 !important;
}

.image-box .rh5v-Overlay_inner {
    display: none !important;
}

.image-box {
    border: 1px dashed #0000004D;
    height: 55px;
    width: 55px;
    margin-right: 14px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
}

@media (min-width: 768px) {
    .image-box {
        height: 70px;
        width: 70px;
    }
}

@media (min-width: 992px) {
    .image-box {
        height: 100px;
        width: 100px;
    }
}

@media (min-width: 1200px) {
    .image-box {
        height: 120px;
        width: 120px;
    }
}

@media (min-width: 1400px) {
    .image-box {
        height: 125px;
        width: 125px;
    }
}

.close-btn {
    position: absolute;
    top: -11px;
    right: 7px;
    font-size: 18px;
    cursor: pointer;
}